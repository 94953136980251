import { useAppDispatch, useAppSelector } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { Col, Form, Row, Select } from 'antd';
import React, { useState } from 'react';
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays } from 'date-fns';
import { RangeType } from 'rsuite/esm/DateRangePicker';
import { convert } from '@utils/convert';
export interface IFormSearchProps {
  handleChangeParam: (paramCurrent: ParamReq) => void;
  paramCurrent?: ParamReq;
}

export default function FormSearch(props: IFormSearchProps) {
  const { handleChangeParam, paramCurrent } = props;
  const { data: DataGame } = useAppSelector((state) => state.gameSlice);
  const [param, setParam] = useState({});
  const [form] = Form.useForm();
  // const handleChange = (_, option) => {
  //   handleChangeParam({ [option.name]: option.value });
  // };

  const handleChange = (event) => {
    if (event.game_id) {
      setParam({ ...param, game_id: event.game_id });
      handleChangeParam({ ...param, game_id: event.game_id });
      // handleDetectDuplicateField(event.game_id, 'game_transfer_id', event);
    } else {
      setParam({ ...param, ...event });
      handleChangeParam({ ...param, ...event });
    }
  };

  return (
    <div className="box-wrap custom-col custom-m-x">
      <Form
        form={form}
        className="search-group"
        onValuesChange={handleChange}
        name="advanced_search"
        layout="horizontal"
        size={'large'}
      >
        <Col span={8} style={{ minWidth: '250px' }}>
          <Form.Item name={'game_id'}>
            <Select
              defaultValue={paramCurrent?.game_id}
              showSearch
              allowClear
              placeholder="Chọn tên trò chơi"
              optionFilterProp="children"
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              options={DataGame.listGame.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </Form.Item>
        </Col>
      </Form>
    </div>
  );
}
