import axios from 'axios';
import Cookies from 'universal-cookie';
import { logout } from '../../utils/index';

const domain = process.env.REACT_APP_API_URL;
const httpRequest = axios.create({
  baseURL: 'https://api.vive8899.net' + '/agent/v1',
});

httpRequest.interceptors.request.use(
  function (config) {
    const cookies = new Cookies();
    const storagedToken = cookies.get('userToken');

    if (storagedToken) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${storagedToken as string}`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpRequest.interceptors.response.use(
  function (res) {
    // if (res.data.message === 'Failed to authenticate') {
    //   logout();
    //   return;
    // }
    return res.data;
  },
  function (error) {
    console.log('error', error);
    const cookies = new Cookies();
    const storagedToken = cookies.get('userToken');
    if (error.response.status === 401 && storagedToken) {
      logout();
    }
    return Promise.reject(error);
  },
);

export default httpRequest;
