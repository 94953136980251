import MenuComponent from '@components/common/menu';
import { useAppSelector } from '@hook/index';
import { IItemListPage } from '@interfaces/Detail';
import ListPage from '@services/ListPage';
import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';

export interface IMenuHeaderProps {}

export default function MenuHeader(props: IMenuHeaderProps) {
  const [listPage, setListPage] = useState<IItemListPage[]>([]);
  const { size } = useAppSelector((state) => state.commonSlice);
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    (async () => {
      const { data: DataRes } = await ListPage.getAll();
      setListPage(DataRes.data);
    })();
  }, []);
  return (
    <div className="layout-full header-agent">
      {size > 768 ? (
        <div className="layout-max">
          <ul className="nav navbar-nav">
            {listPage.map((item) => {
              return (
                <li key={item.id}>
                  <NavLink className="menu-item" to={`/${item.slug === 'trang-chu' ? '' : item.slug}`}>
                    {item.title}
                  </NavLink>
                </li>
              );
            })}
            <li>
              <NavLink className="menu-item" to={`/lien-he`}>
                {'Liên hệ'}
              </NavLink>
            </li>
          </ul>
        </div>
      ) : (
        <MenuComponent data={listPage} />
      )}
    </div>
  );
}
