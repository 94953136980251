import { IUserInfo } from '@interfaces/User';

function convertDateFormat(dateString) {
  const date = new Date(dateString);

  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
}

// const filterRoleWithRouteById = (permissions: IPermissions[]) => {
//   let ListRoute: {
//     roleParents: IPermissions;
//     children: { roleChildren: IPermissions; subChildren: IPermissions[] }[];
//   }[] = [];
//   let ListRouteParent = permissions.filter((level1) => level1.pid === 0);
//   let SubListRoute = ListRouteParent.map((level1) => {
//     return { roleParents: { ...level1 }, children: permissions.filter((level2) => level2.pid === level1.id) };
//   });
//   return ListRoute;
// };

export const toPlainString = (num: any) => {
  return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/, function (a, b, c, d, e) {
    return e < 0 ? b + '0.' + Array(1 - e - c.length).join('0') + c + d : b + c + d + Array(e - d.length + 1).join('0');
  });
};

export const money = (value: any, unit: string) => {
  return `${value.toLocaleString('en-US')}${unit}`;
};

export const moneyInput = (value: any) => {
  return `${Number(value).toLocaleString('en-US')}`;
};

export const moneyInputs = (number) => {
  return Number(parseInt(number)).toLocaleString('en-US');
};

export const pointInput = (value: any) => {
  return `${value.toLocaleString('en-US')}`;
};

export const numberToMoney = (value: any) => {
  return `${Number(value).toLocaleString('en-US').replace(/\./g, ',')}`;
};

const getRole = (data: IUserInfo) => {
  const path = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
  if (path === 'list-user' || path === 'list-agent') {
    return data.roles[0].permissions.filter((item) => item.path.includes(path) || item.path.includes('game-user'));
  }
  return data.roles[0].permissions.filter((item) => item.path.includes(path));
};

const formatDate = (str: string) => {
  let date = new Date(str),
    mnth = ('0' + (date.getMonth() + 1)).slice(-2),
    day = ('0' + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
};

export const numWithCommas = (num) => {
  let parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const convert = {
  formatDate,
  convertDateFormat,
  money,
  moneyInput,
  moneyInputs,
  pointInput,
  numberToMoney,
  getRole,
  numWithCommas,
};
