import * as React from 'react';
import './style.scss';
import { Pagination } from 'antd';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { getAllCategory } from '@store/categorySlice';
import { Link } from 'react-router-dom';
export interface INewComponentProps {}

export default function NewComponent(props: INewComponentProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.categorySlice);
  React.useEffect(() => {
    dispatch(getAllCategory({ page: 1, paginate: 15 }));
  }, []);
  const ChangePage = (pageItem: number, pageSize: number) => {
    dispatch(getAllCategory({ page: pageItem, paginate: 15 }));
  };
  return (
    <div className="layout-full news">
      <div className="layout-max background-event">
        <div className="rightModule">
          <div className="infoModule">
            <div className="inside-title icon icon-newspaper">
              <span>Danh Mục Tin Tức</span>
            </div>
            <div className="newsList">
              {data.listInfo.map((item) => (
                <div key={item.id}>
                  <img src={item.thumbail} alt={item.title} />
                  <div className="txt-darkBlue">{item.title}</div>
                  <div className="txt-gray">{item.description}</div>
                  <div className="btnGroup">
                    <Link className="blue" to={`/tin-tuc/${item.slug}`} state={{ id: item.id, title: item.title }}>
                      Chi tiết
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination-container">
              <Pagination
                current={data.current_page}
                onChange={ChangePage}
                total={Number(data.total)}
                defaultPageSize={15}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
