import { useAppSelector } from '@hook/index';
import * as React from 'react';
import { Helmet } from 'react-helmet';
export interface ISEOProps {
  data?: any;
}

export default function SEO({ data }: ISEOProps) {
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  return (
    <Helmet>
      <meta
        name="description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        name="keywords"
        content={data?.tags || data?.seo_keywords || dataSetting?.listSetting?.seo_keywords || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        property="og:title"
        content={data?.seo_title || dataSetting?.listSetting?.seo_title || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        property="og:description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        property="og:image"
        content={data?.image || data?.thumbail || data?.icon || dataSetting?.listSetting?.logo || ''}
      />
      <meta property="og:url" content={window.location.href} />

      <meta
        name="twitter:title"
        content={data?.seo_title || dataSetting?.listSetting?.seo_title || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        name="twitter:description"
        content={data?.seo_description || dataSetting?.listSetting?.seo_description || 'ĐẠI LÝ - VIVA88VIET'}
      />
      <meta
        name="twitter:image"
        content={data?.image || data?.thumbail || data?.icon || dataSetting?.listSetting?.logo || ''}
      />
      <meta name="twitter:url" content={window.location.href} />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}
