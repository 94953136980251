import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IDepositProps {}

export default function Deposit(props: IDepositProps) {
  return (
    <Link to="/thanh-vien/nap-tien" className="deposit menu-item">
      <i className="fa-solid fa-building-columns"></i>
      <span>Nạp điểm</span>
    </Link>
  );
}
