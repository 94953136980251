import HistoryDeposit from '@components/table-mobile/HistoryDeposit';
import { useAppSelector } from '@hook/index';
import { ParamReq } from '@interfaces/CommonInterface';
import { ITransfer } from '@interfaces/Transfer';
import { convert } from '@utils/convert';
import { Pagination, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
export interface IListMember {
  data: ITransfer[];
  current_page: number;
  total: number;
  loading: boolean;
  handleChangeParam: (param: ParamReq) => void;
}

export default function History(props: IListMember) {
  const { data, current_page, total, loading, handleChangeParam } = props;
  const { data: currentUser } = useAppSelector((state) => state.authSlice);
  /** data for column */
  const columns: ColumnsType<ITransfer> = [
    {
      title: 'Tên người dùng',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      render: (_, record) => <Space size="middle">{record.user.username}</Space>,
    },
    {
      title: 'Hóa đơn',
      dataIndex: 'bill_no',
      key: 'bill_no',
      width: 100,
    },
    {
      title: 'Điểm',
      dataIndex: 'point',
      key: 'point',
      width: 100,
    },
    {
      title: 'Trò chơi',
      dataIndex: 'game',
      key: 'game',
      width: 100,
      render: (_, record) => <Space size="middle">{record.game.title}</Space>,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          {record.status === 0 ? (
            <div style={{ backgroundColor: '#48b0f7', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
              Đang xử lí
            </div>
          ) : record.status === 1 ? (
            <div style={{ backgroundColor: '#52c41a', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
              Thành Công
            </div>
          ) : (
            <div style={{ backgroundColor: 'red', color: 'white', padding: '2px 5px', borderRadius: '5px' }}>
              Thất bại
            </div>
          )}
        </Space>
      ),
    },
    {
      title: 'Tạo lúc	',
      dataIndex: 'created_at',
      key: 'date_create',
      align: 'center',
      width: 200,
      render: (_, record) => <Space size="middle">{convert.convertDateFormat(record.date_create)}</Space>,
    },
  ];

  const ChangePage = (pageItem: number, pageSize: number) => {
    handleChangeParam({ paginate: pageSize, page: pageItem });
  };
  return (
    <>
      <div className="table-wrap">
        <div className="m-top">
          <Table
            className="table wrap-col desktop"
            scroll={{ x: 1024 }}
            loading={loading}
            pagination={false}
            columns={columns || 1}
            dataSource={data}
            rowKey={(record) => record.id}
          />
          <div className="mobile">
            <HistoryDeposit data={data} />
          </div>
          <div className="wrap-pagination">
            {data !== undefined && (
              <Pagination onChange={ChangePage} current={current_page} total={total} showSizeChanger={false} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
