import { IDataLevel } from '@interfaces/Level';
import { moneyInput } from '@utils/convert';
import * as React from 'react';

export interface ITbaleLevelProps {
  data: IDataLevel;
}

export default function TbaleLevel(props: ITbaleLevelProps) {
  const { data } = props;
  return (
    <>
      <div className="layout-full">
        <div className="layout-max">
          <table className="medal">
            <tbody>
              <tr>
                <td>CẬP NHẬT GẦN NHẤT ： 10/25/2022</td>
                <td>
                  <img src={data.vip[0]?.image} alt="vip01" />
                  {data.vip[0]?.level_name}
                </td>
                <td>
                  <img src={data.vip[1]?.image} alt="vip01" />
                  {data.vip[1]?.level_name}
                </td>
                <td>
                  <img src={data.vip[2]?.image} alt="vip01" />
                  {data.vip[2]?.level_name}
                </td>
                <td>
                  <img src={data.vip[3]?.image} alt="vip01" />
                  {data.vip[3]?.level_name}
                </td>
                <td>
                  <img src={data.vip[4]?.image} alt="vip41" />
                  {data.vip[4]?.level_name}
                </td>
                <td>
                  <img src={data.vip[5]?.image} alt="vip01" />
                  {data.vip[5]?.level_name}
                </td>
                <td>
                  <img src={data.vip[6]?.image} alt="vip01" />
                  {data.vip[6]?.level_name}
                </td>
                <td>
                  <img src={data.vip[7]?.image} alt="vip01" />
                  {data.vip[7]?.level_name}
                </td>
                <td>
                  <img src={data.vip[8]?.image} alt="vip01" />
                  {data.vip[8]?.level_name}
                </td>
                <td>
                  <img src={data.vip[9]?.image} alt="vip01" />
                  {data.vip[9]?.level_name}
                </td>
                <td>
                  <img src={data.vip[10]?.image} alt="vip01" />
                  {data.vip[10]?.level_name}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="eventList">
            <tbody>
              <tr className="title">
                <td className="icon icon-nova88">
                  ƯU ĐÃI RIÊNG HẤP DẪN <span className="txt-orange">(VND)</span>
                </td>
                <td colSpan={11} />
              </tr>
              <tr>
                <td>Hạng mức rút tiền trong ngày</td>

                <td>{moneyInput(data.vip[0]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[1]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[2]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[3]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[4]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[5]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[6]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[7]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[8]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[9]?.withdrawal_today)}</td>
                <td>{moneyInput(data.vip[10]?.withdrawal_today)}</td>
              </tr>
              <tr>
                <td>Số tiền nộp trong ngày</td>

                <td>{moneyInput(data.vip[0]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[1]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[2]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[3]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[4]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[5]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[6]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[7]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[8]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[9]?.deposit_money)}</td>
                <td>{moneyInput(data.vip[10]?.deposit_money)}</td>
              </tr>
              <tr>
                <td>Phần thưởng theo hạng</td>

                <td>{data.vip[0]?.level_bonus}</td>
                <td>{data.vip[1]?.level_bonus}</td>
                <td>{data.vip[2]?.level_bonus}</td>
                <td>{data.vip[3]?.level_bonus}</td>
                <td>{data.vip[4]?.level_bonus}</td>
                <td>{data.vip[5]?.level_bonus}</td>
                <td>{data.vip[6]?.level_bonus}</td>
                <td>{data.vip[7]?.level_bonus}</td>
                <td>{data.vip[8]?.level_bonus}</td>
                <td>{data.vip[9]?.level_bonus}</td>
                <td>{data.vip[10]?.level_bonus}</td>
              </tr>
              <tr>
                <td>Phần thưởng theo ngày</td>

                <td>{data.vip[0]?.day_bonus}</td>
                <td>{data.vip[1]?.day_bonus}</td>
                <td>{data.vip[2]?.day_bonus}</td>
                <td>{data.vip[3]?.day_bonus}</td>
                <td>{data.vip[4]?.day_bonus}</td>
                <td>{data.vip[5]?.day_bonus}</td>
                <td>{data.vip[6]?.day_bonus}</td>
                <td>{data.vip[7]?.day_bonus}</td>
                <td>{data.vip[8]?.day_bonus}</td>
                <td>{data.vip[9]?.day_bonus}</td>
                <td>{data.vip[10]?.day_bonus}</td>
              </tr>
              <tr>
                <td>Phần thưởng theo tuần</td>

                <td>{data.vip[0]?.week_bonus}</td>
                <td>{data.vip[1]?.week_bonus}</td>
                <td>{data.vip[2]?.week_bonus}</td>
                <td>{data.vip[3]?.week_bonus}</td>
                <td>{data.vip[4]?.week_bonus}</td>
                <td>{data.vip[5]?.week_bonus}</td>
                <td>{data.vip[6]?.week_bonus}</td>
                <td>{data.vip[7]?.week_bonus}</td>
                <td>{data.vip[8]?.week_bonus}</td>
                <td>{data.vip[9]?.week_bonus}</td>
                <td>{data.vip[10]?.week_bonus}</td>
              </tr>
              <tr>
                <td>Phần thưởng theo tháng</td>

                <td>{data.vip[0]?.month_bonus}</td>
                <td>{data.vip[1]?.month_bonus}</td>
                <td>{data.vip[2]?.month_bonus}</td>
                <td>{data.vip[3]?.month_bonus}</td>
                <td>{data.vip[4]?.month_bonus}</td>
                <td>{data.vip[5]?.month_bonus}</td>
                <td>{data.vip[6]?.month_bonus}</td>
                <td>{data.vip[7]?.month_bonus}</td>
                <td>{data.vip[8]?.month_bonus}</td>
                <td>{data.vip[9]?.month_bonus}</td>
                <td>{data.vip[10]?.month_bonus}</td>
              </tr>
              <tr>
                <td>Phần thưởng theo năm</td>

                <td>{data.vip[0]?.year_bonus}</td>
                <td>{data.vip[1]?.year_bonus}</td>
                <td>{data.vip[2]?.year_bonus}</td>
                <td>{data.vip[3]?.year_bonus}</td>
                <td>{data.vip[4]?.year_bonus}</td>
                <td>{data.vip[5]?.year_bonus}</td>
                <td>{data.vip[6]?.year_bonus}</td>
                <td>{data.vip[7]?.year_bonus}</td>
                <td>{data.vip[8]?.year_bonus}</td>
                <td>{data.vip[9]?.year_bonus}</td>
                <td>{data.vip[10]?.year_bonus}</td>
              </tr>
            </tbody>
          </table>
          <img
            className="event-link"
            src="https://banner.goal123.org/desktop/img/vip/vip_program_vn.jpg"
            alt="vip-program-vn"
          />
        </div>
      </div>
    </>
  );
}
