import { Collapse } from 'antd';
import React, { useState } from 'react';
import './style.scss';
import { IItemListPage } from '@interfaces/Detail';
import { NavLink } from 'react-router-dom';

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;
type Props = {
  data: IItemListPage[];
};
const MenuComponent = ({ data }: Props) => {
  const [keyValue, setKeyValue] = useState<string | string[]>('1');
  const onChange = (key: string | string[]) => {
    setKeyValue(key);
  };

  const handleChangeKey = () => {
    setKeyValue('1');
  };

  return (
    <div className="menu-collapse">
      <Collapse activeKey={keyValue} onChange={onChange}>
        <Panel
          className="menu-agent"
          forceRender={true}
          header={<i className="fa-solid fa-bars"></i>}
          key="2"
          showArrow={false}
        >
          <div className="menu-mobile">
            {data.map((item) => (
              <NavLink
                key={item.id}
                onClick={handleChangeKey}
                className="menu-item"
                to={`/${item.slug === 'trang-chu' ? '' : item.slug}`}
              >
                {item.title}
              </NavLink>
            ))}
            <NavLink onClick={handleChangeKey} className="menu-item" to={`/lien-he`}>
              {'Liên hệ'}
            </NavLink>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default MenuComponent;
