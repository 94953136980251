import * as React from 'react';
import './style.scss';
import { useAppDispatch, useAppSelector } from '@hook/index';
import { useLocation } from 'react-router-dom';
import { getAllGameDetail } from '@store/gameDetailSlice';
export interface IGameDetailProps {}

export default function GameDetailComponent(props: IGameDetailProps) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.getGameDetail);
  let location = useLocation();
  React.useEffect(() => {
    if (!location) return;
    dispatch(getAllGameDetail({ slug: location.pathname.split('/')[location.pathname.split('/').length - 1] }));
  }, [location]);
  return (
    <div className="layout-full news-detail">
      <div className="layout-max background-event">
        <div className="rightModule">
          <div className="infoModule">
            <article>
              <img alt={data.listGameDetail?.title} src={data.listGameDetail?.image} />
              <h1>{data.listGameDetail?.title}</h1>
              <pre>{data.listGameDetail?.description}</pre>
            </article>
          </div>
        </div>
      </div>
    </div>
  );
}
