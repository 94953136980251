import * as React from 'react';
import './style.scss';
import { useAppSelector } from '@hook/index';
export interface LoadingProps {
  sx?: string;
  loadingDefault?: boolean;
}

export default function LoadingModal({ sx, loadingDefault }: LoadingProps) {
  const { loading: loadingAuth } = useAppSelector((state) => state.authSlice);
  const { loading: loadingHome } = useAppSelector((state) => state.homeSlice);
  const { loading: loadingTransfer } = useAppSelector((state) => state.transferSlice);
  const { loading: loadingBankAdmin } = useAppSelector((state) => state.bankAdminSlice);
  const { loading: loadingBankUser } = useAppSelector((state) => state.bankUserSlice);
  const { loading: loadingPoint } = useAppSelector((state) => state.pointSlice);
  const { loading: loadingCategorySlice } = useAppSelector((state) => state.categorySlice);
  const { loading: loadingGetGameDetail } = useAppSelector((state) => state.getGameDetail);

  const loading =
    loadingCategorySlice ||
    loadingAuth ||
    loadingHome ||
    loadingTransfer ||
    loadingBankAdmin ||
    loadingBankUser ||
    loadingPoint ||
    loadingGetGameDetail;

  if (loading) return <></>;
  return (
    <>
      {loadingDefault && (
        <div className="modal-loading-container">
          <img src={require('@assets/logo.png')} alt="logo" />
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </>
  );
}
