import { useAppDispatch, useAppSelector } from '@hook/index';
import { getFooter } from '@store/homeSlice';
import { getSetting } from '@store/settingSlice';
import * as React from 'react';
import { Link } from 'react-router-dom';

export interface IFooterProps {}

export default function Footer(props: IFooterProps) {
  const { data: dataHome } = useAppSelector((state) => state.homeSlice);
  const { data: dataSetting } = useAppSelector((state) => state.settingSlice);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getFooter());
  }, []);

  return (
    <div className="footer">
      <div className="footer-text">{dataSetting.listSetting?.copyright ?? ''}</div>
    </div>
  );
}
